import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Footer from "../components/footer"

const OurBrands = ({ data }) => (
  <>
    <Metatags
      title="daucodesigns | nuestras marcas"
      description="detrás de cada producto, hay una historia"
      url="https://daucodesigns.com"
      image="https://daucodesigns.com/logo-daucodesigns.png"
      keywords="muebles, design"
    />
    <Navbar />
    <div className="height40" />
    <div className="height40" />
    <Container className="big-container">
      <Row>
        <Col>
          <div className="text-center">
            <h4 className="marcas-title nuestras-marcas-title">
              nuestras marcas
            </h4>
          </div>
        </Col>
      </Row>
      <div className="height40" />
      <Row>
        <Col sm={6}>
          <Link to="/bla-station">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.blastation3P)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="blå station"
                  />
                </div>
              </div>
              <div className="brand-logo-wrapper">
                <GatsbyImage
                  image={getImage(data.blastationLogo)}
                  backgroundColor={false}
                  className="img-responsive"
                  alt="blå station"
                />
              </div>
            </div>
          </Link>
        </Col>
        <Col sm={6}>
          <Link to="/bene">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.bene4P)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="bene"
                  />
                </div>
              </div>
              <div className="brand-logo-wrapper">
                <GatsbyImage
                  image={getImage(data.beneLogo)}
                  backgroundColor={false}
                  className="img-responsive"
                  alt="bene"
                />
              </div>
            </div>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Link to="/acousticpearls">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.acousticpearls4P)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="acousticpearls"
                  />
                </div>
              </div>
              <div className="brand-logo-wrapper">
                <GatsbyImage
                  image={getImage(data.acousticpearlsLogo)}
                  backgroundColor={false}
                  className="img-responsive"
                  alt="acousticpearls"
                />
              </div>
            </div>
          </Link>
        </Col>
        <Col sm={6}>
          <Link to="/wiesner-hager">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.wiesnerhager5P)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="wiesner_hager"
                  />
                </div>
              </div>
              <div className="brand-logo-wrapper">
                <GatsbyImage
                  image={getImage(data.wiesnerhagerLogo)}
                  backgroundColor={false}
                  className="img-responsive"
                  alt="wiesner_hager"
                />
              </div>
            </div>
          </Link>
        </Col>
      </Row>
    </Container>
    <div className="height40" />
    <Container className="big-container">
      <Row>
        <Col sm={3}>
          <Link to="/grassoler">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.grassoler1P)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="grassoler"
                  />
                </div>
              </div>
              <div className="brand-logo-wrapper-small">
                <GatsbyImage
                  image={getImage(data.grassolerLogo)}
                  backgroundColor={false}
                  className="img-responsive"
                  alt="grassoler"
                  style={{ padding: "2px" }}
                />
              </div>
            </div>
          </Link>
        </Col>
        <Col sm={3}>
          <Link to="/gotessons">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.Gotessons1P)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="gotessons"
                  />
                </div>
              </div>
              <div className="brand-logo-wrapper-small">
                <GatsbyImage
                  image={getImage(data.gotessonsLogo)}
                  backgroundColor={false}
                  className="img-responsive"
                  alt="gotessons"
                  style={{ padding: "2px" }}
                />
              </div>
            </div>
          </Link>
        </Col>
        <Col sm={3}>
          <Link to="/inclass">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.Inclass1P)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="inclass"
                  />
                </div>
              </div>
              <div className="brand-logo-wrapper-small">
                <GatsbyImage
                  image={getImage(data.inclassLogo)}
                  backgroundColor={false}
                  className="img-responsive"
                  alt="inclass"
                  style={{ padding: "2px" }}
                />
              </div>
            </div>
          </Link>
        </Col>
        <Col sm={3}>
          <Link to="/nardi">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.Nardi1P)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="nardi"
                  />
                </div>
              </div>
              <div className="brand-logo-wrapper-small">
                <GatsbyImage
                  image={getImage(data.nardiLogo)}
                  backgroundColor={false}
                  className="img-responsive"
                  alt="nardi"
                  style={{ padding: "2px" }}
                />
              </div>
            </div>
          </Link>
        </Col>
      </Row>
      <Row>
      <Col sm={3}>
          <Link to="/systemtronic">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.systemtronic1P)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="artis"
                  />
                </div>
              </div>
              <div className="brand-logo-wrapper-small">
                <GatsbyImage
                  image={getImage(data.systemtronicLogo)}
                  backgroundColor={false}
                  className="img-responsive"
                  alt="artis"
                />
              </div>
            </div>
          </Link>
        </Col>
        <Col sm={3}>
          <Link to="/artis">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.artis1P)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="artis"
                  />
                </div>
              </div>
              <div className="brand-logo-wrapper-small">
                <GatsbyImage
                  image={getImage(data.artisLogo)}
                  backgroundColor={false}
                  className="img-responsive"
                  alt="artis"
                />
              </div>
            </div>
          </Link>
        </Col>
        <Col sm={3}>
          <Link to="/kastel">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.kastel1P)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="kastel"
                  />
                </div>
              </div>
              <div className="brand-logo-wrapper-small">
                <GatsbyImage
                  image={getImage(data.kastelLogo)}
                  backgroundColor={false}
                  className="img-responsive"
                  alt="kastel"
                />
              </div>
            </div>
          </Link>
        </Col>
        <Col sm={3}>
          <Link to="/fenabel">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.fenabel1P)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="kastel"
                  />
                </div>
              </div>
              <div className="brand-logo-wrapper-small">
                <GatsbyImage
                  image={getImage(data.fenabelLogo)}
                  backgroundColor={false}
                  className="img-responsive"
                  alt="kastel"
                />
              </div>
            </div>
          </Link>
        </Col>
      </Row>
    </Container>
    <div className="height40" />
    <Footer />
  </>
)

export default OurBrands

export const query = graphql`
  query pageBrands {
    acousticpearls4P: file(
      relativePath: { eq: "brands/acousticpearls/acousticpearls4P.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    acousticpearlsLogo: file(relativePath: { eq: "logos/acousticpearls.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    bene4P: file(relativePath: { eq: "brands/bene/bene4P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    beneLogo: file(relativePath: { eq: "logos/bene.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    blastation3P: file(
      relativePath: { eq: "brands/bla-station/blastation03P.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    blastationLogo: file(relativePath: { eq: "logos/bla-station.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    wiesnerhager5P: file(
      relativePath: { eq: "brands/wiesner-hager/wiesnerhager5P.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    wiesnerhagerLogo: file(relativePath: { eq: "logos/wiesner-hager.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    Gotessons1P: file(
      relativePath: { eq: "brands/gotessons/Gotessons1P.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    gotessonsLogo: file(relativePath: { eq: "logos/gotessons.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    Inclass1P: file(relativePath: { eq: "brands/inclass/Inclass1P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    inclassLogo: file(relativePath: { eq: "logos/inclass.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    Nardi1P: file(relativePath: { eq: "brands/nardi/Nardi1P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    nardiLogo: file(relativePath: { eq: "logos/nardi.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    grassoler1P: file(
      relativePath: { eq: "brands/grassoler/grassoler1P.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    grassolerLogo: file(relativePath: { eq: "logos/grassoler.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    artis1P: file(relativePath: { eq: "brands/artis/artis1P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    artisLogo: file(relativePath: { eq: "logos/artis.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    kastel1P: file(relativePath: { eq: "brands/kastel/kastel1P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    kastelLogo: file(relativePath: { eq: "logos/kastel.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    fenabel1P: file(relativePath: { eq: "brands/fenabel/fenabel1P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    fenabelLogo: file(relativePath: { eq: "logos/fenabel.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    systemtronic1P: file(relativePath: { eq: "brands/systemtronic/systemtronic01P.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    systemtronicLogo: file(relativePath: { eq: "logos/systemtronic.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
  }
`
